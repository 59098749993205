<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
          style="width: 200px;"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Daftar
          </b-card-title>
          <b-card-text class="mb-2">
            Buat akun Advertiser kamu!
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- email -->
              <b-form-group
                label="Alamat Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                  :custom-messages="customMessages"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Masukkan Alamat Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <small v-if="errors.invalid" class="text-danger">{{ errors.invalid }}</small>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|password"
                  :custom-messages="customMessages"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="Masukkan Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>


              <!-- no_handphone -->
              <b-form-group
                label="Nomor Handphone"
                label-for="no-handphone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phoneNumber"
                  vid="phoneNumber"
                  rules="required|integer|min:8"
                  :custom-messages="customMessages"
                >
                  <b-form-input
                    id="no-handphone"
                    v-model="phoneNumber"
                    name="no-handphone"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Masukkan Nomor Handphone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="checkbox1"
                  vid="checkbox1"
                  rules="required"
                >
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="status"
                    name="checkbox-1"
                    :state="errors.length > 0 ? false:null"
                  >
                    Saya setuju dengan
                    <b-link v-b-modal.modal-privacy>Kebijakan Privasi</b-link> dan <b-link v-b-modal.modal-syarat>Syarat dan Ketentuan</b-link> yang berlaku
                  </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || !status"
              >
                Daftar
              </b-button> -->
              <ButtonLoading
                buttonText="Daftar"
                :isLoading="isLoading"
                @action="register"
                :disabled="invalid || !status"
              />
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Sudah punya akun?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Login disini</span>
            </b-link>
          </p>

          <b-modal
            id="modal-privacy"
            centered
            title="Kebijakan Privasi"
            ok-only
            ok-title="Setuju"
            size="lg"
          >
            <b-card-text>
              Croissant jelly-o halvah chocolate sesame snaps.
              Brownie caramels candy canes chocolate cake marshmallow icing lollipop I love.
              Gummies macaroon donut caramels biscuit topping danish.
            </b-card-text>
          </b-modal>

          <b-modal
            id="modal-syarat"
            centered
            title="Syarat dan Ketentuan"
            ok-only
            ok-title="Setuju"
            size="lg"
          >
            <b-card-text>
              Croissant jelly-o halvah chocolate sesame snaps.
              Brownie caramels candy canes chocolate cake marshmallow icing lollipop I love.
              Gummies macaroon donut caramels biscuit topping danish.
            </b-card-text>
          </b-modal>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, VBModal, BModal, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ButtonLoading from '@/views/components/button/ButtonLoading.vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'

// App Name
const { appName, appLogoImage } = $themeConfig.app
export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    ButtonLoading,
    BModal
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      errors: {
        invalid: null
      },
      status: '',
      phoneNumber: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      isLoading: false,
      customMessages: {
        required: 'Harus diisi',
        email: 'Email harus berupa alamat surel yang benar',
        integer: 'No Handphone hanya boleh diisi angka',
        min: 'No. handphone terlalu pendek',
        password: 'Password minimal 6 karakter dengan kombinasi huruf dan angka'
      },
      appName,
      appLogoImage
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      let _ = this
      _.isLoading = true
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            phoneNumber: this.phoneNumber,
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              _.isLoading = false
              this.$router.push({name: 'verification-email', query: { mailto: this.userEmail}})
            })
            .catch(error => {
              _.isLoading = false
              console.log(error)
              _.errors.invalid = error.response.data.message
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
