<template>
    <VueLoadingButton
        class="btn btn-primary btn-block"
        type="submit"
        @click.native="action"
        :loading="isLoading"
        :styled="true"
        style="font-size: 15px"
    >{{ buttonText }}</VueLoadingButton>
</template>

<script>
import VueLoadingButton from 'vue-loading-button'

export default {
    props: ['buttonText', 'isLoading'],
    data() {
        return {

        }
    },
    components: {
        VueLoadingButton
    },
    methods: {
        async action() {
            console.log('action clicked')
            let _ = this
            _.$emit('action')
        }
    }
}
</script>

<style lang="scss">
.spinner {
  -webkit-animation: none
}
</style>