var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"width":"200px"},attrs:{"src":_vm.appLogoImage,"alt":"logo"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Daftar ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Buat akun Advertiser kamu! ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"Alamat Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"Masukkan Alamat Email"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.errors.invalid)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.invalid))]):_vm._e()],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|password","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"Masukkan Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Nomor Handphone","label-for":"no-handphone"}},[_c('validation-provider',{attrs:{"name":"phoneNumber","vid":"phoneNumber","rules":"required|integer|min:8","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"no-handphone","name":"no-handphone","type":"number","state":errors.length > 0 ? false:null,"placeholder":"Masukkan Nomor Handphone"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"checkbox1","vid":"checkbox1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","state":errors.length > 0 ? false:null},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Saya setuju dengan "),_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-privacy",modifiers:{"modal-privacy":true}}]},[_vm._v("Kebijakan Privasi")]),_vm._v(" dan "),_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-syarat",modifiers:{"modal-syarat":true}}]},[_vm._v("Syarat dan Ketentuan")]),_vm._v(" yang berlaku ")],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('ButtonLoading',{attrs:{"buttonText":"Daftar","isLoading":_vm.isLoading,"disabled":invalid || !_vm.status},on:{"action":_vm.register}})],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Sudah punya akun?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Login disini")])])],1),_c('b-modal',{attrs:{"id":"modal-privacy","centered":"","title":"Kebijakan Privasi","ok-only":"","ok-title":"Setuju","size":"lg"}},[_c('b-card-text',[_vm._v(" Croissant jelly-o halvah chocolate sesame snaps. Brownie caramels candy canes chocolate cake marshmallow icing lollipop I love. Gummies macaroon donut caramels biscuit topping danish. ")])],1),_c('b-modal',{attrs:{"id":"modal-syarat","centered":"","title":"Syarat dan Ketentuan","ok-only":"","ok-title":"Setuju","size":"lg"}},[_c('b-card-text',[_vm._v(" Croissant jelly-o halvah chocolate sesame snaps. Brownie caramels candy canes chocolate cake marshmallow icing lollipop I love. Gummies macaroon donut caramels biscuit topping danish. ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }